import React from 'react';
import Header from '../sections/Header';
import Hero from '../sections/Hero';
import Features from '../sections/Features';
import Benefits from '../sections/Benefits';
import Results from '../sections/Results';
import Steps from '../sections/Steps';
import Pricing from '../sections/Pricing';
import Schedule from '../sections/Schedule';
import FAQ from '../sections/FAQ';
import Footer from '../sections/Footer';
import Container from '../components/Container';

const HomePage: React.FC = () => {
  return (
    <div className="App scroll-smooth overflow-y-scroll max-h-screen">
      <Header />
      <Hero />
      <Features />
      <Benefits />
      <Results />
      <Steps />
      <Container backgroundCard id='pricing'>
        <Pricing />
      </Container>
      {/* <Schedule /> */}
      <FAQ />
      <Footer />
    </div>
  )
}

export default HomePage;