import {
  CalendarCheck2,
  ChevronsUp,
  Clock,
  Rocket,
  Smile,
  Workflow,
} from "lucide-react";
import React from "react";
import Container from "../components/Container";

// import { Container } from './styles';

const Benefits: React.FC = () => {
  return (
    <Container>
      <h1 className="text-3xl md:text-5xl font-extrabold text-center mb-2">
        Transforme Sua Experiência de Atendimento ao Cliente
      </h1>
      <h3 className="text-lg md:text-2xl font-medium text-center mb-12">
        Benefícios que podemos trazer para o seu negócio
      </h3>

      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-12 text-black">
        <div className="p-6 rounded-md bg-blueLight">
          <div className="w-14 h-14 rounded-full bg-white flex items-center justify-center">
            <Clock size={32} />
          </div>
          <h3 className="text-xl font-bold mt-6">Aumento de Eficiência</h3>
          <p className="mt-4 text-md">
            Automatize respostas para perguntas comuns e libere tempo da sua
            equipe para questões mais complexas.
          </p>
        </div>

        <div className="p-6 rounded-md bg-primaryLight">
          <div className="w-14 h-14 rounded-full bg-white flex items-center justify-center">
            <Smile size={32} />
          </div>
          <h3 className="text-xl font-bold mt-6">Satisfação do Cliente</h3>
          <p className="mt-4 text-md">
            Forneça respostas rápidas e precisas e mantenha os clientes
            satisfeitos.
          </p>
        </div>

        <div className="p-6 rounded-md bg-greenLight">
          <div className="w-14 h-14 rounded-full bg-white flex items-center justify-center">
            <ChevronsUp size={32} />
          </div>
          <h3 className="text-xl font-bold mt-6">Configuração e Uso Fácil</h3>
          <p className="mt-4 text-md">
            Comece em minutos com nosso processo de integração simples.
          </p>
        </div>

        <div className="p-6 rounded-md bg-greenLight">
          <div className="w-14 h-14 rounded-full bg-white flex items-center justify-center">
            <Workflow size={32} />
          </div>
          <h3 className="text-xl font-bold mt-6">
            Flexibilidade de Integração
          </h3>
          <p className="mt-4 text-md">
            Use assistentes do ChatGPT de maneira personalizada, criando funções
            que se conectam aos serviços que sua empresa já utiliza, além de
            trabalhar com arquivos e dados.
          </p>
        </div>

        <div className="p-6 rounded-md bg-blueLight">
          <div className="w-14 h-14 rounded-full bg-white flex items-center justify-center">
            <Rocket size={32} />
          </div>
          <h3 className="text-xl font-bold mt-6">
            Aumento da Taxa de Conversão
          </h3>
          <p className="mt-4 text-md">
            Capturar leads e responder rapidamente às consultas pode aumentar
            significativamente suas chances de conversão e vendas.
          </p>
        </div>

        <div className="p-6 rounded-md bg-primaryLight">
          <div className="w-14 h-14 rounded-full bg-white flex items-center justify-center">
            <CalendarCheck2 size={32} />
          </div>
          <h3 className="text-xl font-bold mt-6">Disponibilidade 24/7</h3>
          <p className="mt-4 text-md">
            Ofereça suporte e respostas aos seus clientes a qualquer hora do
            dia, garantindo disponibilidade total.
          </p>
        </div>
      </div>
    </Container>
  );
};

export default Benefits;
