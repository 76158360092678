import { MoveRight } from "lucide-react";
import React from "react";
import feature1 from "../assets/images/feature1.png";
import feature2 from "../assets/images/feature2.png";
import feature3 from "../assets/images/feature3.png";
import feature4 from "../assets/images/feature4.png";
import Container from "../components/Container";

const Features: React.FC = () => {
  const renderButon = () => {
    return (
      <a
        href="#pricing"
        className="mt-4 font-semibold hover:pl-2 transition-all"
        aria-current="page"
        rel="noreferrer"
      >
        <div className="flex flex-row gap-x-2 items-center">
          <p className="text-lg">Ver planos</p>
          <MoveRight size={20} />
        </div>
      </a>
    );
  };

  return (
    <Container backgroundCard>
      <h1 className="text-3xl md:text-5xl font-extrabold text-center mb-2">
        Por Que Escolher Nossa Ferramenta?
      </h1>
      <h3 className="text-lg md:text-2xl font-medium text-center mb-12">
        Conheça nossas principais funcionalidades
      </h3>

      <div className="flex flex-col-reverse md:flex-row gap-8 max-w-5xl mx-auto md:items-center">
        <div className="w-full">
          <img src={feature1} alt="" />
        </div>
        <div className=" w-full flex flex-col justify-center">
          <h1 className="font-extrabold mb-4 mt-6 text-3xl text-green">
            Interpretação de Áudio
          </h1>
          <p className="text-lg">
            Analisa mensagens de áudio e fornece respostas precisas, tornando a
            comunicação fluida e dinâmica.
          </p>

          {renderButon()}
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-8 max-w-5xl mx-auto mt-12 md:items-center">
        <div className="flex  w-full flex-col justify-center">
          <h1 className="font-extrabold mb-4 mt-6 text-3xl text-primary">
            Segmentação de Mensagens
          </h1>
          <p className="text-lg">
            Divide grandes mensagens em partes menores e gerenciáveis para
            melhor legibilidade e engajamento. Cada parte menor da mensagem,
            conta com um atraso inteligente para simular o tempo de digitação de
            um humano.
          </p>

          {renderButon()}
        </div>

        <div className="w-full">
          <img src={feature2} alt="" />
        </div>
      </div>

      <div className="flex flex-col-reverse md:flex-row md:items-center gap-8 max-w-5xl mx-auto mt-12 ">
        <div className="w-full">
          <img src={feature3} alt="" />
        </div>
        <div className=" w-full flex flex-col justify-center">
          <h1 className="font-extrabold mb-4 mt-6 text-3xl text-green">
            Compreensão Sequencial
          </h1>
          <p className="text-lg">
            Nossa IA entende a sequência das mensagens dos usuários, percebendo
            quando diferentes mensagens estão relacionadas ao mesmo contexto. O
            assistente espera seu cliente terminar de digitar, para assim
            começar a processar as mensagens, garantindo uma experiência de
            conversa mais fluida e eficiente.
          </p>

          {renderButon()}
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-8 max-w-5xl mx-auto mt-12 md:items-center">
        <div className="flex  w-full flex-col justify-center">
          <h1 className="font-extrabold mb-4 mt-6 text-3xl text-primary">
            Captura e Exportação de Leads
          </h1>
          <p className="text-lg">
            Salva automaticamente os detalhes de contato de quem falou com você
            e permite exportar os dados em formato CSV para uso posterior em
            outras automações.
          </p>

          {renderButon()}
        </div>

        <div className="w-full">
          <img src={feature4} alt="" />
        </div>
      </div>
    </Container>
  );
};

export default Features;
