import React from "react";
import Container from "../components/Container";
import Header from "../sections/Header";
import Footer from "../sections/Footer";

const TermsPage: React.FC = () => {
  return (
    <>
      <Header />
      <Container>
        <h1 className="text-3xl font-bold text-center text-primary mb-10">
          Termos e Condições de Uso do Zatten
        </h1>

        <div className="space-y-8">
          <div>
            <h2 className="font-bold">1. Aceitação dos Termos</h2>
            <p>
              Bem-vindo ao Zatten! Ao utilizar nosso serviço, você concorda com
              os seguintes Termos e Condições. Caso não concorde com algum dos
              termos aqui estabelecidos, pedimos que não utilize nosso serviço.
            </p>
          </div>

          <div>
            <h2 className="font-bold">2. Descrição do Serviço</h2>
            <p>
              O Zatten oferece uma ferramenta que conecta assistentes do ChatGPT
              ao WhatsApp, permitindo automatizar e melhorar a comunicação com
              seus clientes. A configuração é simples e pode ser concluída em
              três passos fáceis: criar um assistente na OpenAI, fornecer as
              credenciais do assistente e o número de telefone, e conectar via
              token.
            </p>
          </div>

          <div>
            <h2 className="font-bold">3. Uso da Ferramenta</h2>
            <h3>3.1 Funcionalidades</h3>
            <ul>
              <li>
                Compreensão Contextual de Mensagens: Nossa IA entende o contexto
                das mensagens dos usuários e responde de forma inteligente e
                contextualizada.
              </li>
              <li>
                Segmentação de Mensagens: Divide mensagens longas em partes
                menores para melhor legibilidade.
              </li>
              <li>
                Compreensão de Áudio: Analisa e responde a mensagens de áudio.
              </li>
              <li>
                Captura de Leads: Salva automaticamente os detalhes de contato
                dos usuários.
              </li>
              <li>
                Flexibilidade de Integração: Permite criar funções
                personalizadas que se conectam aos serviços que sua empresa já
                utiliza, além de manipular arquivos e dados.
              </li>
            </ul>
            <h3>3.2 Disponibilidade</h3>
            <p>
              Nosso serviço está disponível 24/7, garantindo que seus clientes
              possam receber respostas a qualquer momento.
            </p>
          </div>

          <div>
            <h2 className="font-bold">4. Responsabilidade do Usuário</h2>
            <p>O usuário é responsável por:</p>
            <ul>
              <li>Garantir a veracidade das informações fornecidas.</li>
              <li>
                Utilizar o serviço de acordo com as leis e regulamentações
                aplicáveis.
              </li>
              <li>
                Proteger suas credenciais de acesso e não compartilhá-las com
                terceiros.
              </li>
            </ul>
          </div>

          <div>
            <h2 className="font-bold">5. Limitações e Uso Adequado</h2>
            <h3>5.1 Proibições</h3>
            <p>O usuário não deve:</p>
            <ul>
              <li>
                Utilizar o serviço para atividades ilícitas ou não autorizadas.
              </li>
              <li>Tentar acessar sistemas ou redes não autorizadas.</li>
              <li>Enviar conteúdo ofensivo, abusivo ou difamatório.</li>
            </ul>
            <h3>5.2 Limitações Técnicas</h3>
            <p>
              5.2.1 O Zatten não utiliza a API oficial do WhatsApp (Meta). Embora
              ofereçamos um serviço estável e seguro, não podemos garantir a
              compatibilidade contínua com todas as futuras atualizações do
              WhatsApp, e nem garantimos proteção total contra bloqueios de
              chip.
            </p>
            <p>
              5.2.2 O Zatten usa as credenciais privadas de acesso aos serviços da
              OpenAI, embora os dados salvos sejam protegidos por criptografia,
              informa-los ao nosso sistema é de sua total responsabilidade,
              conta e risco.
            </p>
          </div>

          <div>
            <h2 className="font-bold">6. Privacidade e Dados</h2>
            <h3>6.1 Coleta de Dados</h3>
            <p>
              Coletamos informações necessárias para fornecer nossos serviços,
              como nome e número de telefone dos leads.
            </p>
            <h3>6.2 Uso de Dados</h3>
            <p>
              Os dados coletados são usados exclusivamente para fins de operação
              do serviço e não são compartilhados com terceiros, exceto conforme
              exigido por lei.
            </p>
          </div>

          <div>
            <h2 className="font-bold">7. Alterações nos Termos</h2>
            <p>
              Reservamo-nos o direito de alterar estes Termos e Condições a
              qualquer momento. Quaisquer alterações serão notificadas aos
              usuários e continuar a utilizar o serviço após a notificação
              constituirá aceitação dos novos termos.
            </p>
          </div>

          <div>
            <h2 className="font-bold">8. Rescisão</h2>
            <p>
              Podemos suspender ou rescindir seu acesso ao serviço a qualquer
              momento, sem aviso prévio, se acreditarmos que você violou
              qualquer um destes Termos e Condições.
            </p>
          </div>

          <div>
            <h2 className="font-bold">9. Isenção de Garantias</h2>
            <p>
              O Zatten é fornecido "como está" e "conforme disponível", sem
              garantias de qualquer tipo, expressas ou implícitas, incluindo,
              mas não se limitando a, garantias de comercialização, adequação a
              um propósito específico e não violação.
            </p>
          </div>

          <div>
            <h2 className="font-bold">10. Limitação de Responsabilidade</h2>
            <p>
              Em nenhum caso o Zatten será responsável por quaisquer danos
              diretos, indiretos, incidentais, especiais, consequenciais ou
              exemplares, incluindo, mas não se limitando a, danos por perda de
              lucros, boa vontade, uso, dados ou outras perdas intangíveis.
            </p>
          </div>

          <div>
            <h2 className="font-bold">11. Disposições Gerais</h2>
            <p>
              Estes Termos e Condições constituem o acordo completo entre você e
              o Zatten e regem o uso do serviço, substituindo quaisquer acordos
              anteriores.
            </p>
          </div>

          <p>
            A leitura e aceitação destes Termos e Condições são obrigatórias
            para o uso do Zatten. Obrigado por escolher nossa ferramenta para
            melhorar suas interações no WhatsApp!
          </p>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default TermsPage;
