export const groupUrl = "https://chat.whatsapp.com/IQRQ2kiOMUoAE44BiI72BQ"
const register = "https://www.app.zatten.com/"

const checkouts = {
    freelancer: "https://pay.kirvano.com/d966693f-8257-4872-970b-8352e3f84979",
    agency: "https://pay.kirvano.com/306cabd5-1009-425a-9f49-6d7b08090a40"
}

const getCheckoutUrl = (redirectToCheckout = false, type?: keyof typeof checkouts) => {
    return redirectToCheckout && type ? checkouts[type] : register
}

export default getCheckoutUrl