import React from "react";
import getCheckoutUrl from "../config/checkoutUrl";
import { BadgeCheck, Check } from "lucide-react";

const FREELANCER = [
  "1 atendente",
  "Segmentação de Mensagens",
  "Compreensão Sequencial",
  "Conversas ilimitadas",
  "Interpretação de áudio",
  "Interpretação de Imagens",
  "Integrações com ferramentas externas",
  "API e Webhooks"
];

const AGENCY = [
  "White Label",
  "5 atendentes",
  "Suporte premium",
];

interface PricingProps {
  redirectToCheckout?: boolean;
}

const Pricing = ({ redirectToCheckout }: PricingProps) => {
  return (
    <>
      <div className="max-w-2xl mx-auto text-center mb-10 lg:mb-14">
        <h2 className="text-2xl font-bold md:text-4xl md:leading-tight">
          Nosso plano <span className="text-primary">Mensal</span>
        </h2>
        <BadgeCheck size={52} className="text-green  mx-auto mt-12" />

        {redirectToCheckout ? (
          <p className="mt-2 text-xl">
            <span className="font-bold ">
              {" "}
              7 dias de garantia incondicional
            </span>{" "}
            <br /> e cancele quando quiser
          </p>
        ) : (
          <p className="mt-2 text-xl">
            <span className="font-bold ">
              {" "}
              7 dias de teste totalmente gratuito
            </span>{" "}
            <br /> Não fazemos qualquer tipo de cobrança antecipada
          </p>
        )}
      </div>

      <div className="mt-12 flex flex-col md:flex-row justify-center gap-8">
        <div className="flex flex-col max-w-2xl bg-background text-center rounded-xl p-8">

          <h4 className="font-bold text-3xl mt-4">Plano Freelancer</h4>
          <span className="font-regular text-2xl">
            R$ <span className="font-bold text-4xl">197</span>/mês
          </span>

          <ul className="mt-7 space-y-4 text-sm">
            {FREELANCER.map((feature) => (
              <li key={feature} className="flex text-left items-center gap-2">
                <p className="text-green">
                  <Check size={24} />
                </p>
                <span className="text-gray-800 dark:text-gray-400 text-xl">
                  {feature}
                </span>
              </li>
            ))}
          </ul>

          <a
            className={`mt-8 py-4 px-4 inline-flex text-xl justify-center items-center gap-x-2 text-sm font-semibold rounded-md border  text-white hover:scale-105 transition-all border-primary bg-primary`}
            href={getCheckoutUrl(redirectToCheckout, "freelancer")}
            target="_blank"
            rel="noreferrer"
          >
            {redirectToCheckout ? "Assinar agora" : "Experimente por 7 dias"}
          </a>
        </div>

        <div className="flex flex-col col-span-4 border border-primary text-center bg-background rounded-xl p-8">
        <p className="mb-3">
            <span className="inline-flex items-center gap-1.5 py-1.5 px-3 rounded-full text-xs text-white uppercase font-semibold bg-primary">
              Recomendado
            </span>
          </p>

          <h4 className="font-bold text-3xl mt-4">Plano Agência</h4>

          <span className="font-regular text-2xl">
            R$ <span className="font-bold text-4xl">497</span>/mês
          </span>



          <ul className="mt-4 space-y-4 text-sm">
          
            {AGENCY.map((feature) => (
              <li
                key={feature}
                className="flex text-left font-medium items-center gap-2"
              >
                <Check size={22} className="text-green" />
                <span className="text-2xl font-bold">{feature}</span>
              </li>
            ))}
          </ul>

          <ul className="mt-4 space-y-4 text-sm">
            {FREELANCER.filter((feature, index) => index > 0).map((feature) => (
              <li key={feature} className="flex text-left items-center gap-2">
                <p className="text-green">
                  <Check size={24} />
                </p>
                <span className="text-gray-800 dark:text-gray-400 text-xl">
                  {feature}
                </span>
              </li>
            ))}
          </ul>

          <a
            className={`mt-8 py-4 px-4 inline-flex text-xl justify-center items-center gap-x-2 text-sm font-semibold rounded-md border  text-white hover:scale-105 transition-all border-primary bg-primary`}
            href={getCheckoutUrl(true, "agency")}
            target="_blank"
            rel="noreferrer"
          >
            Assinar agora
          </a>
        </div>
      </div>
    </>
  );
};

export default Pricing;
