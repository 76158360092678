import "preline/preline";
import { IStaticMethods } from "preline/preline";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import HomePage from "./pages/Home";
import PricingPage from "./pages/Pricing";
import TermsPage from "./pages/Terms";
import SupportBubble from "./components/SupportBubble";

declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}

const NAMES = [
  "Ana Silva",
  "Bruno Souza",
  "Carlos Lima",
  "Daniela Martins",
  "Eduardo Rocha",
  "Fernanda Gomes",
  "Gabriel Oliveira",
  "Helena Santos",
  "Igor Andrade",
  "Juliana Pereira",
  "Kleber Costa",
  "Larissa Menezes",
  "Marcelo Alves",
  "Natalia Soares",
  "Otávio Barbosa",
  "Patricia Freitas",
  "Quintino Ferreira",
  "Roberta Nogueira",
  "Sergio Camargo",
  "Tatiane Lacerda",
  "William Carvalho",
  "Xavier Cunha",
  "Yasmin Fonseca",
  "Amanda Duarte",
  "Bernardo Vieira",
  "Cecilia Moura",
  "Diego Pires",
  "Elisa Yamada",
  "Fabio Lopes",
  "Giselle Batista",
  "Hugo Teixeira",
  "Isabela Figueiredo",
  "João Macedo",
  "Kelly Nascimento",
  "Luciano Queiroz",
  "Nícolas Batista",
  "Olívia Castro",
  "Paulo Cezar",
  "Raquel Xavier",
  "Samuel Viana",
  "Ubiratan Silva",
  "Valéria Mendes",
  "Wesley Nunes",
  "Yuri Almeida",
  "Zuleica Ferreira",
  "Antonio Junior",
  "Bianca Almeida",
  "Caio Brito",
  "Danielle Souza",
  "Estevão Machado",
  "Flavia Pinheiro",
  "Geraldo Peixoto",
  "Henrique Dias",
  "Ivone Lopes",
  "Jairo Menezes",
  "Karina Lima",
  "Leandro Rocha",
  "Marcia Castro",
  "Nelson Martins",
  "Osvaldo Cruz",
  "Priscila Tavares",
  "Quirino Gomes",
  "Ricardo Almeida",
  "Sandra Oliveira",
  "Thiago Monteiro",
  "Ursula Andrade",
  "Vanessa Sampaio",
  "Wilson Santos",
  "Yago Silva",
  "Zenaide Barbosa",
  "Afonso Ribeiro",
  "Beatriz Cerqueira",
  "Cláudio Nascimento",
  "Denise Fagundes",
  "Emerson Batista",
  "Giovanni Martins",
  "Heitor Vasconcelos",
  "Iara Campos",
  "Júlio Cesar",
  "Kátia Souza",
  "Leonardo Dias",
  "Mirela Ferreira",
  "Noemi Cunha",
  "Otaviana Marques",
  "Pablo Siqueira",
  "Queren Hapuque",
  "Sílvia Pfeifer",
  "Vanderlei Cordeiro",
  "Yolanda Soares",
];

const views = [
  90, 112, 73, 102, 120, 130, 99, 110, 90, 112, 73, 102, 120, 130, 99, 110, 47,
  231, 271, 238, 134, 87, 84, 91, 375,
];

const randomView = views[Math.floor(Math.random() * views.length)];

// setInterval(() => {
//   const name = NAMES[Math.floor(Math.random() * NAMES.length)];
//   const Message = `🔥 ${name} acabou de assinar o plano individual!`;

//   toast.success(Message, {
//     position: "bottom-left",
//     autoClose: 2000,
//     hideProgressBar: true,
//     type: "default",
//      style: {
//       //  backgroundColor: "var(--alert-color)",
//       //  color: "var(--alert-text)",
//        marginBottom: "60px",
//      },
//   });
// }, 20000);

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/pricing",
    element: <PricingPage />,
  },
  {
    path: "/terms",
    element: <TermsPage />,
  },
]);

function App() {
  return (
    <>
      <ToastContainer rtl={false} />
      <RouterProvider router={router} />
      <SupportBubble />
    </>
  );
}

export default App;
