import React from "react";
import Container from "../components/Container";
import Footer from "../sections/Footer";
import Header from "../sections/Header";
import Pricing from "../sections/Pricing";

// import { Container } from './styles';

const PricingPage: React.FC = () => {
  return (
    <div className="App scroll-smooth overflow-y-scroll max-h-screen">
      <Header hideTestButton />
      <Container>
        <Pricing redirectToCheckout />
      </Container>
      <Footer />
    </div>
  );
};

export default PricingPage;
