import React from "react";

import { motion } from "framer-motion";

import { Menu, X } from "lucide-react";
import { Link } from "react-router-dom";
import Logo from "../assets/logo.svg";

const variants = {
  open: { height: "auto" },
  closed: { height: 0 },
};

interface Props {
  hideTestButton?: boolean;
}

const Header = ({ hideTestButton }: Props) => {
  const [open, setOpen] = React.useState(false);

  const RenderSubscribe = () => {
    if (hideTestButton) return null;

    return (
      <a
        href="#pricing"
        className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-md bg-primary text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
        aria-current="page"
        rel="noreferrer"
      >
        Testar grátis
      </a>
    );
  };

  const RenderEnter = () => {
    return (
      <a
        href="https://app.zatten.com/login"
        target="_blank"
        className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-md bg-text text-background hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
        aria-current="page"
        rel="noreferrer"
      >
        Acessar
      </a>
    );
  };

  return (
    <header className="sticky border-b-4 border-card top-0 inset-x-0 flex flex-wrap sm:justify-start sm:flex-nowrap z-50 w-full bg-headerBackground text-sm py-3 md:py-3">
      <nav
        className="relative container w-full mx-auto px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex items-center justify-between">
          <Link to="/">
            <img
              className="w-10 h-10 md:w-12 md:h-12 flex-none text-xl font-semibold text-white"
              src={Logo}
              alt=""
            />
          </Link>

          <div className="sm:hidden">
            <button
              type="button"
              className="size-9 flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-text disabled:opacity-50 disabled:pointer-events-none"
              onClick={() => setOpen(!open)}
            >
              {open ? <X /> : <Menu />}
            </button>
          </div>
        </div>
        <motion.div
          animate={open ? "open" : "closed"}
          variants={variants}
          layoutId="header"
          className="overflow-hidden h-0 sm:hidden"
        >
          <div className="flex flex-col gap-y-4 gap-x-0 mt-5 sm:flex-row sm:items-center sm:justify-end sm:gap-y-0 sm:gap-x-7 sm:mt-0 sm:ps-7">
            <RenderSubscribe />
            <RenderEnter />
          </div>
        </motion.div>

        <div className="flex sm:flex-row sm:items-center sm:justify-end sm:gap-y-0 sm:gap-x-7 sm:mt-0 sm:ps-7 hidden sm:flex">
          <RenderSubscribe />
          <RenderEnter />
        </div>
      </nav>
    </header>
  );
};

export default Header;
