import React from "react";

interface ContainerProps {
  children: React.ReactNode;
  backgroundCard?: boolean;
  id?: string;
}

const Container = ({ children, backgroundCard, id }: ContainerProps) => {
  return (
    <div className={`${backgroundCard ? "bg-card" : "bg-background"}`} id={id}>
        <div className={`container mx-auto px-6 py-20`}>{children}</div>
    </div>
  );
};

export default Container;
