import Vimeo from "@u-wave/react-vimeo";
import { MoveRight } from "lucide-react";
import React, { useEffect } from "react";
import Hero1 from "../assets/images/Hero.png";
import Hero2 from "../assets/images/Hero2.png";
import Container from "../components/Container";
import getCheckoutUrl, { groupUrl } from "../config/checkoutUrl";

const Hero: React.FC = () => {
  const [logo, setLogo] = React.useState(
    window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
      ? Hero2
      : Hero1
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const handleChange = (e: any) => {
      setLogo(e.matches ? Hero2 : Hero1);
    };

    mediaQuery.addListener(handleChange);
    return () => mediaQuery.removeListener(handleChange);
  }, []);

  return (
    <Container>
      <div className="flex justify-center items-center">
        <img className="flex max-w-[200px]" src={logo} alt="" />
      </div>
      <div className="w-full md:w-3/4 mx-auto text-center">
        <h1 className="text-3xl md:text-5xl font-extrabold text-center">
          <span className="underline decoration-primary">
            Conecte o ChatGPT ao WhatsApp
          </span>{" "}
          e Automatize suas Conversas com Inteligência Artificial
        </h1>
        <h3 className="text-xl md:text-2xl font-regular text-center mt-6">
          Simplifique suas interações, economize tempo e aumente a satisfação do
          cliente com respostas rápidas e precisas
        </h3>
      </div>
      <div className="flex flex-col md:flex-row justify-center items-center mt-8 gap-4">
        <a
          href={groupUrl}
          target="_blank"
          className="p-[3px] items-center gap-x-2 text-sm font-semibold rounded-lg bg-greenLight text-white hover:scale-105 transition-all"
          aria-current="page"
          rel="noreferrer"
        >
          <div className="bg-green py-5 px-12 rounded-md flex flex-row gap-x-2 items-center">
            <p className="text-lg text-white">Grupo do WhatsApp</p>
          </div>
        </a>

        <a
          href="#pricing"
          className="inline-flex p-[3px] items-center gap-x-2 text-sm font-semibold rounded-lg bg-primaryLight text-white hover:scale-105 transition-all"
          aria-current="page"
          rel="noreferrer"
        >
          <div className="bg-primary py-5 px-12 rounded-md flex flex-row gap-x-2 items-center">
            <p className="text-lg">Testar por 7 dias</p>
            <MoveRight size={20} />
          </div>
        </a>
      </div>
      <div className="w-full mt-12 rounded-lg overflow-hidden">
        <Vimeo
          video="948554823"
          autoplay
          controls={false}
          loop
          muted
          responsive
          autopause={false}
          onLoaded={() => console.log("Video loaded")}
          onError={() => console.log("Video error")}
          playsInline
        />
      </div>
    </Container>
  );
};

export default Hero;
