import React from "react";
import Container from "../components/Container";

const Results: React.FC = () => {
  return (
    <Container backgroundCard>
      <div className="grid gap-6 sm:gap-12 items-center lg:grid-cols-5 lg:gap-8">
      <div className="text-center lg:text-left lg:col-span-2">
          
          <h4 className="text-lg sm:text-2xl font-bold text-text">
          Nós ajudamos empresas a automatizar seus atendimentos.
          </h4> 
        </div>

        <div className="text-center">
          <p className="mt-2 sm:mt-3 text-4xl sm:text-6xl font-bold text-blue-600 text-primary">
            40+
          </p>
          <h4 className="text-lg sm:text-xl font-semibold ">
          Empresas usando
          </h4>
        </div>

        <div className="text-center">
          
          <p className="mt-2 sm:mt-3 text-4xl sm:text-6xl font-bold text-blue-600 text-green">
            500+
          </p>
          <h4 className="text-lg sm:text-xl font-semibold ">
         Chats/dia
          </h4>
        </div>

        <div className="text-center">
          
          <p className="mt-2 sm:mt-3 text-4xl sm:text-6xl font-bold text-blue">
            70%
          </p>
          <h4 className="text-lg sm:text-xl font-semibold ">
         Mais conversão
          </h4>
        </div>
      </div>
    </Container>
  );
};

export default Results;
