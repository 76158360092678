import React from "react";
import Container from "../components/Container";
import { ChevronDown, ChevronUp } from "lucide-react";
import { motion } from "framer-motion";

const variants = {
  open: { height: "auto" },
  closed: { height: 0 },
};

const QUESTIONS = [
  {
    title: "Como funciona o seu serviço?",
    content:
      "Nosso serviço permite que você conecte assistentes de inteligência artificial ao WhatsApp. Você só precisa fornecer as credenciais do seu assistente e o número do WhatsApp que deseja conectar.",
  },
  {
    title: "É seguro fornecer as credenciais do meu assistente?",
    content:
      "Sim, todas as informações fornecidas são protegidas por criptografia e utilizadas apenas para conectar o assistente ao WhatsApp. Não compartilhamos suas informações com terceiros.",
  },
  {
    title: "Quanto tempo leva para configurar o serviço?",
    content:
      "A configuração é rápida e simples. Após fornecer as credenciais do assistente e o número do WhatsApp, você receberá um token que deve ser inserido no WhatsApp para permitir que nossa ferramenta responda às conversas.",
  },
  {
    title:
      "Existe algum limite para o número de conversas que meu assistente pode responder?",
    content:
      "Não, não há limites para o número de conversas que seu assistente pode responder. Conseguimos ter conversas ilimitadas pois, diferente de outras ferramentas, não te revendemos tokens de IA, você conecta seu proprio assistente e define quantos tokens ele vai usar e quando ele vai trabalhar por você . Porém a nossa plataforma tem um limite de salvamento de leads que varia de acordo com o plano escolhido.",
  },
  {
    title:
      "É possível integrar o meu assistente de inteligência artificial com outras ferramentas ou plataformas que minha empresa já usa?",
    content:
      "Sim, podemos ajudar a integrar seu assistente de inteligência artificial com outras ferramentas ou plataformas que sua empresa já usa, para uma experiência mais integrada.",
  },
];

const FAQ: React.FC = () => {
  const [openIndex, setOpenIndex] = React.useState<number>(0);

  const toggleAccordion = (index: number) => {
    setOpenIndex(index);
  };

  return (
    <Container>
      <div>
        <div className="max-w-2xl mx-auto text-center mb-10 lg:mb-14">
          <h2 className="text-2xl font-bold md:text-4xl md:leading-tigh text-primary">
            Suas dúvidas respondidas!
          </h2>
          <p className="mt-1 text-gray-600 dark:text-neutral-400">
            Respostas para as dúvidas mais frequentes dos nossos usuários
          </p>
        </div>

        <div className="mx-auto max-w-2xl">
          <div className="space-y-4">
            {QUESTIONS.map((question, index) => (
              <div className={`rounded-xl p-6 bg-card `} key={index}>
                <button
                  className="pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-bold text-start"
                  onClick={() => toggleAccordion(index)}
                >
                  {index + 1} - {question.title}
                  {openIndex === index ? (
                    <ChevronUp className="flex-shrink-0 size-5" size={24} />
                  ) : (
                    <ChevronDown className="flex-shrink-0 size-5" size={24} />
                  )}
                </button>
                <motion.div
                  animate={openIndex === index ? "open" : "closed"}
                  variants={variants}
                  layoutId={openIndex.toString()}
                  className="overflow-hidden h-0"
                >
                  <div>
                    <motion.h2 className="font-regular">
                      {question.content}
                    </motion.h2>
                  </div>
                </motion.div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default FAQ;
