import React from "react";
import Container from "../components/Container";
import step1 from "../assets/images/step1.png";
import step2 from "../assets/images/step2.png";
import step3 from "../assets/images/step3.png";


const Steps: React.FC = () => {
  return (
    <Container>
        <h1 className="text-3xl md:text-5xl font-extrabold text-center mb-2">Conecte, Automatize e Engaje</h1>
        <h3 className="text-lg md:text-2xl font-medium text-center mb-12">Veja como é fácil usar o <span className="text-primary">zatten</span></h3>

      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-12">
        <div className="p-6 rounded-md bg-card relative text-center">
          <span className="bg-primary absolute -top-4 left-1/2 -translate-x-1/2 mx-auto rounded-full px-3 py-1 text-center text-sm text-white">
            Passo 1
          </span>

          <img src={step1} alt="" />

          <h1 className="font-extrabold mb-2 mt-6">Crie Seu Assistente de IA</h1>
          <p>Cadastre-se na OpenAI e crie seu assistente ChatGPT personalizado, nós fornecemos um prompt modelo.</p>
        </div>

        <div className="p-6 rounded-md bg-card relative text-center">
          <span className="bg-primary absolute -top-4 left-1/2 -translate-x-1/2 mx-auto rounded-full px-3 py-1 text-center text-sm text-white">
            Passo 2
          </span>

          <img src={step2} alt="" />

          <h1 className="font-extrabold mb-2 mt-6">Forneça as Credenciais e Número de Telefone</h1>
          <p>Insira as credenciais do seu assistente e o número de telefone que deseja conectar.</p>
        </div>

        <div className="p-6 rounded-md bg-card relative text-center">
          <span className="bg-primary absolute -top-4 left-1/2 -translate-x-1/2 mx-auto rounded-full px-3 py-1 text-center text-sm text-white">
            Passo 3
          </span>

          <img src={step3} alt="" />

          <h1 className="font-extrabold mb-2 mt-6">Conecte com um Token</h1>
          <p>Gere um token de conexão na nossa plataforma, insira no seu WhatsApp, e pronto!</p>
        </div>
      </div>
    </Container>
  );
};

export default Steps;
